export const EXTERNAL_LINKS = {
  BurrBear: {
    Home: 'https://burrbear.io',
    Analytics: 'https://dune.com/burrbear',
    BalForGas:
      'https://docs.burrbear.io/core-concepts/bal-burrbear-governance-token/bal-for-gas',
    BugBounty: 'https://immunefi.com/bounty/burrbear/',
    Docs: 'https://docs.burrbear.io',
    Forum: 'https://forum.burrbear.io/',
    Grants: 'http://grants.burrbear.community/',
    Social: {
      Discord: 'https://discord.com/invite/burrbear',
      Github: 'https://github.com/burrbear-dev/',
      Mail: 'mailto:contact@burrbear.io',
      Medium: 'https://medium.com/@burrbear',
      Linkedin: 'https://www.linkedin.com/company/burrbear-dev/',
      Twitter: 'https://x.com/moneygoesburr',
      Youtube: 'https://www.youtube.com/',
    },
    Vote: 'https://vote.burrbear.io/',
  },
  Gauntlet: {
    Home: 'https://gauntlet.network',
  },
  Ethereum: {
    Wallets: 'https://ethereum.org/en/wallets',
  },
  Element: {
    Home: 'https://element.fi',
    Earn: 'https://app.element.fi/mint',
    Pools: {
      LUSD: 'https://app.element.fi/pools/0x56F30398d13F111401d6e7ffE758254a0946687d',
      USDC: 'https://app.element.fi/pools/0x7Edde0CB05ED19e03A9a47CD5E53fC57FDe1c80c',
    },
  },
  Copper: {
    Home: 'https://fjordfoundry.com/?utm_source=burrbear&utm_medium=website',
    Auctions: (poolAddress: string, networkPrefix = '') =>
      `https://${networkPrefix}copperlaunch.com/auctions/${poolAddress}`,
  },
  Tracer: {
    Home: 'https://mycelium.xyz/',
  },
  Gyroscope: {
    Home: 'https://gyro.finance/',
  },
  Beets: {
    Home: 'https://beets.fi/',
  },
  Xave: {
    Home: 'https://www.xave.co/',
  },
  Sense: {
    Home: 'https://sense.finance/',
  },
};
